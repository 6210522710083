#sp-cta{
	display: none;
}
#footer-link{
	width: 1200px;
	margin: 0 auto;
	padding: 100px 10px;
	@include flex(wrap,flex-end,space-between);
	position: relative;
	svg{
		position: absolute;
		top: 50%;
		left: 10px;
		-ms-transform: translate(0, -50%);
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%);
		width: 170px;
	}
	.pearents-menu{
		width: 250px;
		list-style: none;
		border-right: 1px solid #999999;
		padding: 0 30px 0 30px;
		li{
			.child-menu{
				list-style: none;
				margin-left: 10px;
				li{
					a,
					a:link,
					a:visited,
					a:active{
						display: block;
						padding-bottom: 10px;
						font-size: 11px;
						font-size: 1.1rem;
						color: $col-link;
					}

				}
			}
			a,
			a:link,
			a:visited,
			a:active{
				font-weight: bold;
				font-size: 14px;
				font-size: 1.4rem;
				display: block;
				padding-bottom: 10px;
				color: $col-link;
				span{
					padding-left: 14px;
					font-weight: bold;
					font-size: 10px;
					font-size: 1rem;
				}
			}
			a:hover{
			}
		}
	}
	.pearents-menu.last-menu{
		li{
			a,
			a:link,
			a:visited,
			a:active{
				font-size: 12px;
				font-size: 1.2rem;
			}
		}
	}
}
footer#colophon{
	width: 100%;
	min-width: 1200px;
	margin: 0 auto;
	background: #3b4650;
	text-align: center;
	color: #fff;
	font-size: 11px;
	font-size: 1.1rem;
	padding: 10px 0;
	p{
		margin: 0;
	}
}
.hidden-nav{
	background: #a4a4a4;
	text-align: center;
	padding: 6px 0;
	a,
	a:link,
	a:visited,
	a:active{
		color: #fff;
		font-size: 11px;
		font-size: 1.1rem;
		padding-right: 10px;
	}
	a:hover{
	}
	// a:after{
	// 	display: inline-block;
	// 	content: '';
	// 	height: 10px;
	// 	width: 1px;
	// 	background: red;
	// }
}

@include media(sp){
#sp-cta{
	display: block;
	background: #fff;
	position: fixed;
	z-index: 999;
	bottom: 0px;
	left: 0;
	width: 100%;
	padding: 10px 0;
	a,
	a:link,
	a:visited,
	a:active{
		text-align: center;
		// background: #296966;
		background: rgb(41,105,102);
		background: -moz-linear-gradient(0deg, rgba(41,105,102,1) 0%, rgba(55,153,148,1) 100%);
		background: -webkit-linear-gradient(0deg, rgba(41,105,102,1) 0%, rgba(55,153,148,1) 100%);
		background: linear-gradient(0deg, rgba(41,105,102,1) 0%, rgba(55,153,148,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#296966",endColorstr="#379994",GradientType=1);
		color: #fff;
		font-size: 15px;
		font-size: 1.3rem;
		font-weight: bold;
		display: block;
		padding: 15px 30px;
		margin: 0 auto;
		width: 290px;
		.fas{
			padding-left: 15px;
		}
	}
	a:hover{
	}
}
footer#colophon{
	min-width: auto;
	width: auto;
	margin: 0 auto;
	padding: 10px 0 100px 0;
	#footer-nav{
		width: 100%;
		min-width: auto;
		display: block;
		.footer-logo{
			padding-right: 0;
			text-align: center;
			display: block;
		}
		#footer-menu{
			display: block;
			text-align: center;
			a,
			a:link,
			a:visited,
			a:active{
				border: none;
				padding: 10px 0;
				display: block;
			}
			a:hover{
			}
			li:first-child{
				a,
				a:link,
				a:visited,
				a:active{
				}
			}
		}
	}
	#footer-mov{
		height: 550px;
		figure{
		}
		a,
		a:link,
		a:visited,
		a:active{
			svg{
			}
		}
		a:hover{
		}
		figure{
		}
		#video{
		}
	}
	#footer-mov:after {
	}
	.site-info{
		p{
		}
	}
}

}
// SMP
@include media(sp){
#footer-link{
	width: auto;
	display: block;
	padding: 50px 10px;
	svg{
		position: static;
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		margin: 0 auto 50px auto;
		display: block;
	}
	.pearents-menu{
		padding: 0 10px;
		border: none;
		width: 100%;
		li{
			.child-menu{
				list-style: none;
				margin-left: 10px;
				li{
					a,
					a:link,
					a:visited,
					a:active{
						display: block;
						padding-bottom: 10px;
						font-size: 11px;
						font-size: 1.1rem;
					}

				}
			}
			a,
			a:link,
			a:visited,
			a:active{
				font-weight: bold;
				font-size: 14px;
				font-size: 1.4rem;
				display: block;
				padding-bottom: 10px;
				span{
					padding-left: 14px;
					font-weight: bold;
					font-size: 10px;
					font-size: 1rem;
				}
			}
			a:hover{
			}
		}
	}
}
}
// SMP
// tab
@media all and (min-width: $brakeWSP) {
}
// tab
// PC
@media all and (min-width: $brakeWPC) {
}
// PC