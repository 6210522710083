body{
	.sec-com{
		padding: 100px 0;
		min-width: 1200px;
		.sec-com-wrap{
			.nomal-ttl{
				@include fonts-alfabet;
				font-weight: 700;
				text-align: center;
				font-size: 30px;
				font-size: 3rem;
				margin: 0;
				color: #2e4c69;
				position: relative;
				span{
					display: block;
					font-size: 16px;
					font-size: 1.6rem;
					padding: 30px 0 0 0;
				}
			}
			.nomal-ttl:before{
				position: absolute;
				top: 57%;
				left: 50%;
				-ms-transform: translate(-50%, -50%);
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				content: "";
				display: block;
				height: 4px;
				width: 150px;
				background: #2e4c69;
			}
		}
	}

	.about-courage{
		position: relative;
		margin: 50px 0;
		padding: 0;
		.sec-com-wrap{
			position: relative;
			z-index: 30;
		}
		.about-img{
			// width: 600px;
			width: 45%;
			height: 400px;
			background: url(../img/about-img01.jpg) no-repeat center;
			position: absolute;
			top: 50%;
			left: 0;
			margin: -200px 0 0 7%;
		}
		.about-ttl{
			color: #2e4c69;
			@include flex(wrap,left,space-between);
			-ms-flex-align: center;
			align-items: center;
			padding-bottom: 44px;
			span{
				padding-left: 30px;
				font-size: 16px;
				font-size: 1.6rem;
			}
		}
		h2{
			@include fonts-alfabet;
			font-size: 30px;
			font-size: 3rem;
			margin: 0;
			padding: 0 130px 0 0;
			position: relative;
		}
		h2:after{
			content: "";
			display: block;
			height: 1px;
			width: 100px;
			background: #2e4c69;
			position: absolute;
			z-index: 1;
			top: 50%;
			right: 0;
		}
		p{
			margin: 0;
			line-height: 2;
		}
		strong{
			font-size: 18px;
			font-size: 1.8rem;
			color: #2e4c69;
			margin-bottom: 30px;
		}
		.about-detail{
			padding: 150px 0;
			width: 40%;
			margin-right: 5%;
			margin-left: auto;
		}
	}
	.about-courage:after{
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: 70%;
		height: 100%;
		content: "";
		background: #eaedf0;
	}
	#circle-img{
		padding: 100px 0;
		#circle-img3{
			margin: 0 auto;
			width: 600px;
			position: relative;
			img{
				width: 600px;
			}
		}
		#circle-txt01,
		#circle-txt02,
		#circle-txt03{
			position: absolute;
			font-size: 14px;
			font-size: 1.4rem;
			border-bottom: 1px solid #2e4c69;
			padding-bottom: 10px;
		}
		#circle-txt01{
			top: -30px;
			right: -160px;
		}
		#circle-txt02{
			top: 120px;
			left: -190px;
		}
		#circle-txt03{
			bottom: -70px;
			right: -230px;
		}
		#circle-txt01:before{
			content: "";
			display: block;
			width: 80px;
			height: 1px;
			background: #2e4c69;
			position: absolute;
			bottom: -29px;
			left: -66px;
			transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
		}
		#circle-txt02:before{
			content: "";
			display: block;
			width: 50px;
			height: 1px;
			background: #2e4c69;
			position: absolute;
			bottom: -19px;
			right: -42px;
			transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}
		#circle-txt03:before{
			content: "";
			display: block;
			width: 110px;
			height: 1px;
			background: #2e4c69;
			position: absolute;
			bottom: 38px;
			left: -93px;
			transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}
	}
	.feature-content{
		.feature-block{
			@include flex(wrap,left,space-between);
			background: #eaedf0;
			width: 900px;
			margin: 50px auto 0 auto;
			padding: 40px;
			position: relative;
			.bg-ttl{
				position: absolute;
				@include fonts-alfabet;
				font-weight: 900;
				line-height: 0.9;
				color: #afb8c1;
				font-size: 43px;
				font-size: 4.3rem;
				right: 0;
				top: 0;
			}
			.feature-block-img{
				width: 35%;
			}
			.feature-block-detail{
				width: 65%;
				padding: 0 0 0 30px;
				h3{
					margin: 0 0 10px 0;
					line-height: 1.3;
					color: $blue;
					font-size: 26px;
					font-size: 2.6rem;
					font-weight: bold;
				}
				p{
					margin: 0;
					line-height: 1.9;
				}
			}
		}
	}
	.results-content{
		background: #eaedf0;
		.results-block-wrap,
		.results-block-wrap-flow{
			@include flex(wrap,left,space-between);
			width: 1080px;
			margin: 50px auto 0 auto;
			.results-block{
				border-top: 5px solid #2e7572;
				padding: 20px;
				@include flex(wrap,left,space-between);

				-ms-flex-align: center;
				align-items: center;
				width: 48%;
				background: #fff;
				margin: 1% 1%;
				.results-block-detail{
					height: 100%;
					width: 290px;
					padding-right: 20px;
					p{
						margin: 0;
					}
					.fact-ttl{
						font-size: 23px;
						font-size: 2.3rem;
						line-height: 1;
						@include fonts-alfabet;
						font-weight: 700;
						color: #2e7572;
						position: relative;
					}
					.fact-ttl-num{
						font-size: 40px;
						font-size: 4rem;
						display: block;
					}
					.fact-ttl:before{
						content: "";
						display: block;
						position: absolute;
						top: 20px;
						left: 70px;
						height: 1px;
						width: 70%;
						background: #2e7572;
					}
				}
				.results-graf{
					// overflow: hidden;
					// position: relative;
					width: 170px;
					// height: 170px;
					// border-radius: 100px;
					// background: rgb(234,84,85);
					// background: -moz-linear-gradient(-50deg, rgba(234,84,85,1) 0%, rgba(254,182,146,1) 100%);
					// background: -webkit-linear-gradient(-50deg, rgba(234,84,85,1) 0%, rgba(254,182,146,1) 100%);
					// background: linear-gradient(-50deg, rgba(234,84,85,1) 0%, rgba(254,182,146,1) 100%);
					// filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea5455",endColorstr="#feb692",GradientType=1);
					.results-graf-inner{
						// @include center;
						// background: #fff;
						// width: 140px;
						// height: 140px;
						// border-radius: 100px;
					}
					.results-graf-inner-block{
						// @include center;
						// display: block;
						// width: 100%;
						// @include fonts-alfabet;
						// font-weight: 900;
					}
					p{
						text-align: center;
						margin: 0;
						color: #2e4c69;
						font-weight: bold;
					}
					strong{
						display: block;
						text-align: center;
						font-size: 36px;
						font-size: 3.6rem;
						color: #2e7572;
						line-height: 1;
						.number{
							font-size: 56px;
							font-size: 5.6rem;
						}
					}
				}
				.results-graf:before{
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 50%;
					margin-left: -60px;
					border-right: 50px solid transparent;
					border-bottom: 86.6025px solid #fff;
					border-left: 20px solid transparent; 
					transform: rotate(170deg);
					-ms-transform: rotate(170deg);
					-webkit-transform: rotate(170deg);
				}
				.graf-full.results-graf:before{
					display: none;
				}
			}
			.results-caption{
				font-size: 13px;
				font-size: 1.3rem;
				text-align: right;
				width: 100%;
				padding: 30px 10px 0 10px;
			}
		}
	}
	.voice-content{
		#voice-slide{
			width: 1000px;
			margin: 0 auto;
			.slick-track{
				@include flex;
			}
			.slick-prev{
				left: -50px;
			}
			.slick-next{
				right: -30px;
			}
			.slick-prev:before,
			.slick-next:before{
				color: #2e4c69;
				font-size: 30px;
				opacity: 1;
				filter: alpha(opacity=100);
				cursor: pointer;
			}
			.voice-block{
				width: 400px;
				border-top: 5px solid #2e7572;
				background: #f7f7f5;
				margin: 50px 20px 0 0;
				height: auto;
				position: relative;
			}
			.voice-block-detail{
				padding: 10px 10px 70px 10px;
				a,
				a:link,
				a:visited,
				a:active{
					position: absolute;
					bottom: 20px;
					left: 50%;
					-ms-transform: translate(-50%, 0);
					-webkit-transform: translate(-50%, 0);
					transform: translate(-50%, 0);
					display: block;
					text-align: center;
					background: #3e7bb5;
					color: #fff;
					@include fonts-alfabet;
					font-weight: 700;
					width: 220px;
					margin: 0 auto;
					padding: 10px 0;
					.fas{
						padding-left: 10px;
					}
				}
				a:hover{
					opacity: 0.6;
					filter: alpha(opacity=60);
				}
			}
		}
	}
	.recomend-content{
		background: url(../img/recomend-bg.jpg) repeat center;
		.recomend-ttl{
			color: #2e4c69;
			text-align: center;
			font-size: 34px;
			font-size: 3.4rem;
			margin: 0 0 -60px 0;
			span{
				display: block;
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: normal;
				padding-top: 10px;
			}
		}
		.recomend-block{
			width: 900px;
			margin: 0 auto;
			background: #fff;
			padding: 130px 100px 100px 100px;
			.recomend-block-line{
				@include flex(wrap,left,space-between);
				-ms-flex-align: center;
				align-items: center;
				padding: 20px 10px;
				border-top: 1px solid #587087;
				span{
					@include fonts-alfabet;
					color: #2e4c69;
					font-weight: 900;
					font-size: 26px;
					font-size: 2.6rem;
					width: 18%;
				}
				p{
					margin: 0;
					width: 82%;
				}
			}
			.recomend-block-line:last-child{
				border-bottom: 1px solid #587087;
			}
		}
		#recomend-accodion{
			display: none;
		}
		#recomend-accodion-show{
			border: 1px solid #587087;
			position: relative;
			width: 300px;
			margin: 50px auto 0 auto;
			padding: 20px 10px;
			text-align: center;
			cursor: pointer;
			@include trans;
			opacity: 1;
			filter: alpha(opacity=100);
			span{
				padding-right: 10px;
			}
			.fas{
				@include trans;
			}
		}
		#recomend-accodion-show.show{
			.fas{
				transform: rotate(-180deg);
				-ms-transform: rotate(-180deg);
				-webkit-transform: rotate(-180deg);
				-webkit-transform-origin:50% 50%;
				-ms-transform-origin:50% 50%;
				transform-origin:50% 50%;
			}
		}
		#recomend-accodion-show{
			opacity: 0.6;
			filter: alpha(opacity=60);
		}
		// #recomend-accodion-show:before, 
		// #recomend-accodion-show:after {
		// 	display: block;
		// 	content: '';
		// 	background-color: #3b4650;
		// 	border-radius: 10px;
		// 	position: absolute;
		// 	width: 30px;
		// 	height: 1px;
		// 	top: 50%;
		// 	right: 10px;
		// }
		// #recomend-accodion-show:before {
		// 	width: 1px;
		// 	height: 30px;
		// 	top: 50%;
		// 	right: 24px;
		// 	margin-top: -14px;
		// 	@include trans;
		// }
	}
	.enter-content{
		.sec-com-wrap{
			@include flex(wrap,left,space-between);
			-ms-flex-align: center;
			align-items: center;
			position: relative;
		}
		.enter-block{
			position: relative;
			padding: 150px 0;
			width: 50%;
			margin-right: auto;
			margin-left: 20%;
			z-index: 10;
			h2{
				font-size: 30px;
				font-size: 3rem;
				color: #2e4c69;
				margin: 0;
				position: relative;
				padding-bottom: 20px;
			}
			h2:before{
				position:absolute;
				bottom: 0;
				left: 0;
				content: "";
				display: block;
				height: 5px;
				width: 150px;
				background: #2e4c69;
			}
			ul{
				margin: 0;
				padding: 30px 0 0 0;
				list-style: none;
				li{
					font-weight: bold;
					color: #2e4c69;
					font-size: 24px;
					font-size: 2.4rem;
					.list-num{
						font-size: 38px;
						font-size: 3.8rem;
					}
					.per-num{
						font-size: 16px;
						font-size: 1.6rem;
					}
				}
				.last-list{
					font-size: 18px;
					font-size: 1.8rem;
					margin-top: 20px;
					.per-num{
						font-size: 12px;
						font-size: 1.2rem;
					}
				}
				li:last-child{
					margin-top: 0;
				}
			}
			.enter-graf{
				position: absolute;
				right: -130px;
				top: 50%;
				width: auto;
				margin-top: -150px;
			}
		}
		.sec-com-wrap:after{
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			width: 30%;
			height: 100%;
			content: "";
			background: #eaedf0;
		}
	}
	.course-content{
		.course-lead{
			text-align: center;
			p{
				font-size: 16px;
				font-size: 1.6rem;
			}
		}
		.course-block-wrap{
			.course-block{
				width: 900px;
				margin: 50px auto 0 auto;
				padding: 30px 300px 80px 60px;
				box-shadow: 0px 0px 16px 5px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(10,10,10,0.02);
				position: relative;
				h3{
					color: #2e4c69;
					font-weight: 900;
					font-size: 30px;
					font-size: 3rem;
					margin: 0 0 20px 0;
				}
				p{
					font-size: 16px;
					font-size: 1.6rem;
					margin: 0;
					line-height: 1.8;
				}
				a,
				a:link,
				a:visited,
				a:active{
					position: absolute;
					right: 30px;
					top: 50%;
					margin-top: -30px;
					display: block;
					text-align: center;
					background: #3e7bb5;
					color: #fff;
					@include fonts-alfabet;
					font-weight: 700;
					width: 250px;
					padding: 20px 0;
					.fas{
						padding-left: 10px;
					}
				}
				a:hover{
					opacity: 0.6;
					filter: alpha(opacity=60);
				}
			}
		}
	}
	.price-content{
		background: #eaedf0;
		.price-block{
			background: #fff;
			padding: 50px 40px;
			width: 900px;
			margin: 50px auto 0 auto;
			.price-list{
				list-style: none;
				padding: 0;
				margin: 0;
				@include flex(wrap,left,space-between);
				-ms-flex-align: center;
				align-items: center;
				li{
					color: #fff;
					background: #2e4c69;
					text-align: center;
					padding: 20px;
					width: 31%;
					margin: 0 1%;
				}
			}
			.price-list.single{
				display: block;
				li{
					width: 100%;
				}

			}
			.price-month{
				width: 525px;
				margin: 110px auto 0 auto;
				padding: 50px 0 30px 0;
				text-align: center;
				background: #eaedf0;
				h3{
					display: block;
					background: #5084b5;
					color: #fff;
					width: 300px;
					margin: -100px auto 0 auto;
					padding: 24px 0;
					font-size: 26px;
					font-size: 2.6rem;
				}
				.price-num{
					font-size: 46px;
					font-size: 4.6rem;
					@include fonts-alfabet;
					color: #2e4c69;
					font-weight: 700;
					span{
						font-size: 23px;
						font-size: 2.3rem;
					}
				}
				.price-coution-inner{
					text-align: center;
					font-size: 10px;
					font-size: 1rem;
					padding: 10px;
				}
			}
		}
		.price-caption{
			color: #2e4c69;
			width: 600px;
			margin: 30px auto 0 auto;
			border: 1px solid #2e4c69;
			padding: 30px;
			line-height: 1.9;
			text-align: center;
		}
		.price-block.corp{
			.price-month{
				width: 100%;
				padding: 50px 20px;
				.price-num{
					font-size: 20px;
					font-size: 2rem;
				}
			}
		}
		.price-coution{
			display: block;
			padding-right: 20px;
			text-align: right;
			font-size: 1.3rem;
		}
	}
	.coach-content{
		.coach-block{
			@include flex(wrap,left,space-between);
			// -ms-flex-align: center;
			// align-items: center;
			width: 900px;
			margin: 0 auto;
			border-top: 1px solid #3b4650;
			border-bottom: 1px solid #3b4650;
			margin-top: 50px;
			.coach-img{
				width: 300px;
				height: auto;
				min-height: 300px;
				border-left: 10px solid #3b4650;
				border-right: 1px solid #3b4650;
				background-repeat: no-repeat;
				background-size: cover;
			}
			.coach-img01{
				background: url(../img/interview-img01.jpg) no-repeat center;

			}
			.coach-img02{
				background: url(../img/interview-img02.jpg) no-repeat center;

			}
			.coach-detail{
				width: 600px;
				border-right: 1px solid #3b4650;
				padding: 10px 20px;
				h3{
					margin: 0 0 10px 0;
					font-size: 24px;
					font-size: 2.4rem;
					color: #2e4c69;
					span{
						padding-left: 10px;
						font-size: 16px;
						font-size: 1.6rem;
					}
				}
				p{
					line-height: 1.8;
					margin: 0;
				}
			}
		}
	}
	.mission-content{
		h2{
			display: block;
			width: 900px;
			padding: 10px 0;
			background: #3b4650;
			color: #fff;
			text-align: center;
			font-size: 36px;
			font-size: 3.6rem;
			// @include fonts-alfabet;
			font-weight: 700;
			margin: 0 auto;
		}
		.mission-lead{
			text-align: center;
			width: 750px;
			margin: 80px auto 0 auto;
			position: relative;
			.mission-lead-inner{
				h3{
					@include font-m;
					padding: 50px 30px;
				}
			}
			.mission-lead-inner:before {
				content: '';
				width: 160px;
				height: 1px;
				background: #000;
				position: absolute;
				bottom: 0;
				right: -20px;
			}
			.mission-lead-inner:after {
				content: '';
				width: 1px;
				height: 90px;
				background: #000;
				position: absolute;
				bottom: -20px;
				right: 0;
			}
		}
		.mission-lead:before {
			content: '';
			width: 160px;
			height: 1px;
			background: #000;
			position: absolute;
			top: 0;
			left: -20px;
		}
		.mission-lead:after {
			content: '';
			width: 1px;
			height: 90px;
			background: #000;
			position: absolute;
			top: -20px;
			left: 0;
		}
		.mission-list{
			list-style: none;
			width: 700px;
			text-align: center;
			margin: 80px auto 0 auto;

			li{
				font-weight: 900;
				font-size: 22px;
				font-size: 2.2rem;
				border-bottom: 1px solid #3b4650;
				padding: 16px 5px;
			}
		}
	}
	.middle-catch-content{
		background: url(../img/middle-catch-lead.jpg) repeat center;
		background-size: cover;
		.sec-com-wrap{
			width: 700px;
			padding: 36px 40px;
			margin: 0 auto;
			background: rgba(59,70,80,0.8);
			color: #fff;
			p{
				margin: 0;
				line-height: 1.8;
			}
		}
	}
	.faq-content{
		.faq-block-wrap{
			width: 900px;
			margin: 50px auto;
			.answer-block{
				display: none;
			}
			.faq-block{
				border-top: 1px solid #3b4650;
				padding: 30px 20px;
				cursor: pointer;
				h3{
					margin: 0;
				}
				.question{
					font-size: 22px;
					font-size: 2.2rem;
					color: #3b4650;
					position: relative;
					padding-right: 40px;
					text-indent: -1em;
					padding-left: 2em;
					.fas{
						font-size: 3px;
						font-size: 3rem;
						padding-right: 20px;
					}
				}
				.question{

				}
				.question:before, .question:after {
					display: block;
					content: '';
					background-color: #3b4650;
					border-radius: 10px;
					position: absolute;
					width: 30px;
					height: 1px;
					top: 13px;
					right: 0;
				}
				.question:before {
					width: 1px;
					height: 30px;
					top: 0;
					right: 14px;
					@include trans;
				}
				.answer{
					padding: 30px 0 0 0;
					font-size: 22px;
					font-size: 2.2rem;
					text-indent: -2em;
					padding-left: 2em;
					span{
						color: #44719c;
						font-size: 30px;
						font-size: 3rem;
						padding-right: 20px;
					}
				}
				.answer-txt{
					margin: 0;
					padding: 10px 0 10px 50px;
					a,
					a:link,
					a:visited,
					a:active{
						text-decoration: underline;
					}
					a:hover{
						opacity: 0.6;
						filter: alpha(opacity=60);
					}
				}
			}
			.faq-block:first-child{
				.answer-block{
					display: block;
				}
			}
			.faq-block:last-child{
				border-bottom: 1px solid #3b4650;
			}
			.faq-block.open{
				.question:before {
					height: 0;
				}
				.answer-block{
				}
			}
		}
	}
	#sns{
		padding: 100px 0;
		.sns-list{
			width: 900px;
			margin: 0 auto;
			border: 1px solid #282828;
			padding: 20px;
			list-style: none;
			@include flex(wrap,center,space-between);
			font-size: 30px;
			font-size: 3rem;
				a,
				a:link,
				a:visited,
				a:active{
					color: #2e4c69;
					padding: 0 20px;
				}
				a:hover{
					opacity: 0.6;
					filter: alpha(opacity=60);
				}
		}
	}
	.cta-section{
		min-width: 1200px;
		background: #f7f7f5;
		position: relative;
		.line-top{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 6px;
			background: rgb(153,222,130);
			background: -moz-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			background: -webkit-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			background: linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99de82",endColorstr="#4cbb93",GradientType=1);
		}
		.line-bottom{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 6px;
			background: rgb(153,222,130);
			background: -moz-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			background: -webkit-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			background: linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99de82",endColorstr="#4cbb93",GradientType=1);
		}
		.section-wrap{
			width: 1200px;
			margin: 0 auto;
			padding: 70px 10px;
		}
		h2{
			color: #2e4c69;
			text-align: center;
			display: block;
			font-size: 34px;
			font-size: 3.4rem;
		}
		strong{
			color: #050505;
			text-align: center;
			display: block;
			font-size: 18px;
			font-size: 1.8rem;
		}
		#cta-men{
			position: absolute;
			top: -87px;
			right: -7px;
			img{
				width: 120px;
			}
		}
		.fukidashi-wrap{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.fukidashi{
				position: relative;
			    width: calc(33.333% - 13.33333px);
			    margin-top: 50px;
			    padding: 19px 30px;
			    background: #fff;
			    -webkit-filter: drop-shadow(0 3px 6px rgba(0,0,0,.16));
			    filter: drop-shadow(0 3px 6px rgba(0,0,0,.16));
			    text-align: center;
			    font-weight: bold;
			}
			.fukidashi:after{
				position: absolute;
				width: 0;
				height: 0;
				border-color: #fff transparent transparent;
				border-style: solid;
				content: "";
			}
			.fukidashi.left:after{
				right: 40px;
				bottom: -16px;
				border-width: 16px 0px 0 30px;
			}
			.fukidashi.center:after{
				right: 50%;
				margin-right: -30px;
				bottom: -16px;
				border-width: 30px 30px 0 30px;
			}
			.fukidashi.right:after{
				left: 40px;
				bottom: -16px;
				border-width: 16px 30px 0 0;
			}
		}
		.cta-btn{
			position: relative;
			strong{
				position: absolute;
				top: -50px;
				left: 50%;
				margin-left: -150px;
				font-size: 12px;
				font-size: 1.2rem;
				text-align: left;
			}
			a,
			a:link,
			a:visited,
			a:active{
				position: relative;
				display: block;
				width: 340px;
				margin: 130px auto 0 auto;
				text-align: center;
				color: #fff;
				opacity: 1;
				filter: alpha(opacity=100);
				font-weight: bold;
				padding: 20px 0px;
				font-size: 14px;
				font-size: 1.4rem;
				background: rgb(153,222,130);
				background: -moz-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
				background: -webkit-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
				background: linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99de82",endColorstr="#4cbb93",GradientType=1);
				.fas{
					padding-left: 15px;
				}
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
		}
	}
	.news-content{
		#news-wrap{
			width: 900px;
			margin: 50px auto;
			height: 300px;
			overflow-y: scroll;
			a,
			a:link,
			a:visited,
			a:active{
				border-top: 1px solid #3b4650;
				color: #2e4c69;
				display: block;
				opacity: 1;
				filter: alpha(opacity=100);
				position: relative;
				padding: 20px;
				h3{
					margin: 0;
				}
				.news-date{
					display: none;
				}
				.cat-name{
					padding: 2px 6px;
					font-size: 12px;
					font-size: 1.2rem;
					background: #3b4550;
					color: #fff;
				}
				.cat-name.blog{
					background: #296966;
				}

				.cat-name.news{
					background: #3e7bb5;
				}
				.fas{
					position: absolute;
					top: 50%;
					right: 30px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
			a:last-child{
				border-bottom: 1px solid #3b4650;
			}
		}
		// #news-wrap{
		#news-wrap::-webkit-scrollbar{
			width: 10px;
		}
		#news-wrap::-webkit-scrollbar-track{
			background: #fff;
			border: none;
			border-radius: 10px;
			box-shadow: inset 0 0 2px #777; 
		}
		#news-wrap::-webkit-scrollbar-thumb{
			background: #ccc;
			border-radius: 10px;
			box-shadow: none;
		}
		// }
	}
}
@include media(sp){
body{
	.sec-com{
		min-width: auto;
		padding: 50px 0;
		.sec-com-wrap{
			.nomal-ttl{
				span{
				}
			}
			.nomal-ttl:before{
			}
		}
	}
	.about-courage{
		padding: 0 10px;
		.sec-com-wrap{
		}
		.about-img{
			width: 100%;
			height: 300px;
			background: url(../img/about-img01.jpg) no-repeat center;
			background-size: cover;
			position: static;
			top: 0;
			left: 0;
			margin: 0 0 30px 0;
		}
		.about-ttl{
			color: #2e4c69;
			@include flex(wrap,left,space-between);
			-ms-flex-align: center;
			align-items: center;
			padding-bottom: 20px;
			padding-left: 10px;
			span{
				padding: 0;
			}
		}
		h2{
			line-height: 1.4;
			padding: 0 90px 0 0;
		}
		h2:after{
			width: 50px;
		}
		p{
			padding: 0 10px;
		}
		strong{
			padding: 0 10px;
			display: block;
			margin-bottom: 20px;
		}
		.about-detail{
			padding: 50px 0;
			width: auto;
			margin-right: 0;
			margin-left: auto;
		}
	}
	#circle-img{
		padding: 50px 10px;
		#circle-img3{
			width: auto;
			img{
				width: 100%;
			}
		}
		#circle-txt01,
		#circle-txt02,
		#circle-txt03{
			position: absolute;
			font-size: 11px;
			font-size: 1.1rem;
			border-bottom: 1px solid #2e4c69;
			padding-bottom: 6px;
		}
		#circle-txt01{
			top: -40px;
			right: 10px;
			text-shadow: #fff 0px 0 3px;
		}
		#circle-txt02{
			top: 120px;
			left: 10px;
			text-shadow: #fff 0px 0 3px;
		}
		#circle-txt03{
			bottom: -30px;
			right: 10px;
			text-shadow: #fff 0px 0 3px;
		}
		#circle-txt01:before{
			display: none;
		}
		#circle-txt02:before{
			display: none;
		}
		#circle-txt03:before{
			display: none;
		}
	}
	.feature-content{
		padding: 50px 10px;
		.feature-block{
			width: auto;
			display: block;
			padding: 60px 20px 20px 20px;
			.bg-ttl{
			}
			.feature-block-img{
				width: auto;
				margin-bottom: 20px;
			}
			.feature-block-detail{
				width: auto;
				padding: 0;
				h3{
					font-size: 23px;
					font-size: 2.3rem;
					br{
						display: none;
					}
				}
				p{
					margin: 0;
					line-height: 1.9;
				}
			}
		}
	}
	.results-content{
		padding: 50px 10px;
		.results-block-wrap,
		.results-block-wrap-flow{
			display: block;
			width: auto;
			margin: 50px auto 0 auto;
			padding: 0 10px;
			.results-block{
				display: block;
				width: auto;
				margin: 20px 0;
				.results-block-detail{
					width: auto;
					padding-right: 0;
					p{
						margin: 0;
					}
					.fact-ttl{
					}
					.fact-ttl-num{
					}
					.fact-ttl:before{
					}
				}
				.results-graf{
					margin: 20px auto;
					.results-graf-inner{
					}
					.results-graf-inner-block{
					}
					p{
					}
					strong{
						.number{
						}
					}
				}
				.results-graf:before{
				}
				.graf-full.results-graf:before{
				}
			}
		}
	}
	.voice-content{
		#voice-slide{
			width: auto;
			.slick-prev{
				left: -50px;
			}
			.slick-next{
				right: -30px;
			}
			.slick-prev:before,
			.slick-next:before{
				color: #2e4c69;
				font-size: 30px;
				opacity: 1;
				filter: alpha(opacity=100);
				cursor: pointer;
			}
			.voice-block{
				width: 400px;
				border-top: 5px solid #2e7572;
				background: #f7f7f5;
				margin: 50px 20px 0 0;
			}
			.voice-block-detail{
				padding: 10px 10px 70px 10px;
				a,
				a:link,
				a:visited,
				a:active{
					width: 90%;
					.fas{
						padding-left: 10px;
					}
				}
				a:hover{
					opacity: 0.6;
					filter: alpha(opacity=60);
				}
			}
		}
	}
	.recomend-content{
		padding: 50px 10px;

		.recomend-ttl{
			font-size: 28px;
			font-size: 2.8rem;
			margin: 0;
			span{
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
		.recomend-block{
			width: auto;
			margin: 0 auto;
			background: #fff;
			padding: 30px 10px 10px 10px;
			.recomend-block-line{
				display: block;
				span{
					width: auto;
				}
				p{
					width: auto;
				}
			}
			.recomend-block-line:last-child{
				border-bottom: 1px solid #587087;
			}
		}
	}
	.enter-content{
		.sec-com-wrap{
			display: block;
			padding: 0 10px;
		}
		.enter-block{
			width: auto;
			margin: 0;
			padding: 50px 0;
			h2{
			}
			h2:before{
			}
			ul{
				margin-bottom: 50px;
				li{
					.list-num{
					}
					.per-num{
					}
				}
				.last-list{
					font-size: 18px;
					font-size: 1.8rem;
					margin-top: 20px;
					.per-num{
						font-size: 12px;
						font-size: 1.2rem;
					}
				}
				li:last-child{
					margin-top: 0;
				}
			}
			.enter-graf{
				margin: 0;
				position: static;
			}
		}
		.sec-com-wrap:after{
			width: 70%;
		}
	}
	.course-content{
		padding: 50px 10px;
		.course-lead{
			p{
			}
		}
		.course-block-wrap{
			.course-block{
				width: auto;
				padding: 30px;
				h3{
					font-size: 25px;
					font-size: 2.5rem;
				}
				p{
					font-size: 14px;
					font-size: 1.4rem;
				}
				a,
				a:link,
				a:visited,
				a:active{
					position: static;
					display: block;
					margin:  20px auto 0 auto;
					.fas{
						padding-left: 10px;
					}
				}
				a:hover{
					opacity: 0.6;
					filter: alpha(opacity=60);
				}
			}
		}
	}
	.price-content{
		padding: 50px 30px;
		.price-block{
			width: auto;
			padding: 30px 20px;
			.price-list{
				display: block;
				li{
					margin: 10px 0;
					width: 100%;
				}
			}
			.price-list.single{
				display: block;
				li{
					width: 100%;
				}

			}
			.price-month{
				width: auto;
				padding: 30px 0;
				margin: 20px auto 0 auto;
				h3{
					width: 80%;
					margin: 0 auto 0 auto;
					padding: 18px 0;
					font-size: 18px;
					font-size: 1.8rem;
				}
				.price-num{
					font-size: 30px;
					font-size: 3rem;
					span{
						font-size: 16px;
						font-size: 1.6rem;
					}
				}
			}
		}
		.price-caption{
			color: #2e4c69;
			width: auto;
			margin: 30px auto 0 auto;
			border: 1px solid #2e4c69;
			padding: 30px;
			line-height: 1.9;
			text-align: center;
		}
	}
	.coach-content{
		margin: 50px 10px;
		.coach-block{
			width: auto;
			display: block;
			border-left: 1px solid #3b4650;
			.coach-img{
				width: 100%;
				height: auto;
				min-height: 300px;
				border-top: 10px solid #3b4650;
				border-left: none;
				border-right: 1px solid #3b4650;
				background-size: contain;
				background-position: center;
			}
			.coach-img01{
			}
			.coach-img02{
			}
			.coach-detail{
				width: auto;
				border-right: 1px solid #3b4650;
				padding: 10px 20px;
				h3{
					margin: 0 0 10px 0;
					font-size: 24px;
					font-size: 2.4rem;
					color: #2e4c69;
					span{
						font-size: 16px;
						font-size: 1.6rem;
					}
				}
				p{
					line-height: 1.8;
					margin: 0;
				}
			}
		}
	}
	.mission-content{
		padding: 50px 10px;
		h2{
			width: auto;
			font-size: 26px;
			font-size: 2.6rem;
		}
		.mission-lead{
			width: auto;
			padding: 30px;
			.mission-lead-inner{
				h3{
					@include font-m;
					padding: 50px 30px;
				}
			}
			.mission-lead-inner:before {
				width: 90px;
				height: 1px;
				bottom: 20px;
				right: 0px;
			}
			.mission-lead-inner:after {
				width: 1px;
				height: 70px;
				bottom: 0px;
				right: 20px;
			}
		}
		.mission-lead:before {
			width: 90px;
			height: 1px;
			top: 20px;
			left: 0px;
		}
		.mission-lead:after {
			width: 1px;
			height: 70px;
			top: 0px;
			left: 10px;
		}
		.mission-list{
			width: auto;
			li{
				font-size: 18px;
				font-size: 1.8rem;
			}
		}
	}
	.middle-catch-content{
		padding: 100px 10px;
		.sec-com-wrap{
			width: auto;
			padding: 20px 35px;
			p{
				margin: 0;
				line-height: 1.8;
			}
		}
	}
	.faq-content{
		.faq-block-wrap{
			padding: 0 10px;
			width: auto;
			.answer-block{
			}
			.faq-block{
				h3{
				}
				.question{
					// padding-left: 1.5rem;
					// text-indent: -1.3rem;
					.fas{
					}
				}
				.question{
					font-size: 20px;
					font-size: 2rem;
				}
				.question:before, .question:after {
				}
				.question:before {
				}
				.answer{
					font-size: 20px;
					font-size: 2rem;
					span{
					}
				}
				.answer-txt{
					margin: 0;
					padding: 10px 0;
				}
			}
			.faq-block:first-child{
				.answer-block{
					display: block;
				}
			}
			.faq-block:last-child{
			}
			.faq-block.open{
				.question:before {
				}
				.answer-block{
				}
			}
		}
	}
	#sns{
		padding: 50px 10px;
		.sns-list{
			width: auto;
			padding: 20px 10px;
			font-size: 20px;
			font-size: 2rem;
				a,
				a:link,
				a:visited,
				a:active{
					color: #2e4c69;
					padding: 0 5px;
				}
				a:hover{
					opacity: 1;
					filter: alpha(opacity=100);
				}
		}
	}
	.cta-section{
		min-width: auto;
		.line-top{
		}
		.line-bottom{
		}
		.section-wrap{
			width: auto;
		}
		h2{
			font-size: 30px;
			font-size: 3rem;
		}
		strong{
			font-size: 16px;
			font-size: 1.6rem;
		}
		.fukidashi-wrap{
			display: block;
			.fukidashi{
				width: auto;
				margin-top: 30px;
			}
			.fukidashi:after{
				position: absolute;
				width: 0;
				height: 0;
				border-color: #fff transparent transparent;
				border-style: solid;
				content: "";
			}
			.fukidashi.left:after{
				left: 40px;
				bottom: -16px;
				border-width: 16px 30px 0 0;
			}
			.fukidashi.center:after{
				left: 40px;
				bottom: -16px;
				border-width: 16px 30px 0 0;
			}
			.fukidashi.right:after{
				left: 40px;
				bottom: -16px;
				border-width: 16px 30px 0 0;
			}
		}
		.cta-btn{
			strong{
			}
			a,
			a:link,
			a:visited,
			a:active{
				width: 100%;
			}
			a:hover{
				opacity: 1;
				filter: alpha(opacity=100);
			}
		}
	}
	.news-content{
		.sec-com-wrap{
			padding: 0 20px;
		}
		#news-wrap{
			width: auto;
			padding: 0;
			margin: 50px auto;
			height: 380px;
			a,
			a:link,
			a:visited,
			a:active{
				border-top: 1px solid #3b4650;
				color: #2e4c69;
				display: block;
				opacity: 1;
				filter: alpha(opacity=100);
				position: relative;
				padding: 20px 50px 20px 20px;
				h3{
					margin: 0;
				}
				.news-date{
					display: none;
				}
				.cat-name{
					padding: 2px 6px;
					font-size: 12px;
					font-size: 1.2rem;
					background: #3b4550;
					color: #fff;
				}
				.cat-name.blog{
					background: #296966;
				}

				.cat-name.news{
					background: #3e7bb5;
				}
				.fas{
					position: absolute;
					top: 50%;
					right: 30px;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
				}
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
			a:last-child{
				border-bottom: 1px solid #3b4650;
			}
		}
	}
}
}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

