body.archive.common,
body.category.common{
	#primary-block{
		@include flex(wrap,left,space-between);
		width: 1200px;
		margin: 0 auto;
	}

	#primary{
		padding: 100px 0;
		width: 950px;
		margin: 0 auto;
		.page-title{
			text-align: center;
			font-size: 30px;
			font-size: 3rem;
			margin-bottom: 40px;
		}
		h1,h2,h3,h4,h5,h6{
			
			margin: 0;
		}
		.post-link{
			display: block;
			margin-bottom: 40px;
			.post-img-wrap{
				width: 250px;
				height: 250px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
			.post-txt{
				width: 700px;
				padding-left: 30px;
			}
			.entry-header{

			}
			.post-date{

			}
			.post-ttl{
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		a,
		a:link,
		a:visited,
		a:active{
			@include trans;
			opacity: 1;
			filter: alpha(opacity=100);

			// font-size: 14px;
			// font-size: 1.4rem;
			// color: #000;
			// font-weight: 300;
			// padding: 20px 16px;
			// @include flex;
			// -ms-flex-align: center;
			// align-items: center;
			.post-img-wrap{
				position: relative;
				@include trans;
				opacity: 1;
				filter: alpha(opacity=100);
				// -ms-transform: scale(1);
				// -webkit-transform: scale(1);
				// transform: scale(1);
			}
			.post-img-wrap:after,
			.post-img-wrap:before {
				content: "";
				display: block;
				width: 30px;
				height: 30px;
				position: absolute;
				@include trans;
			}
			.post-img-wrap:before {
				border-top: 1px solid #ccc;
				border-left: 1px solid #ccc;
				top: 0;
				left: 0;
			}
			.post-img-wrap:after {
				border-bottom: 1px solid #ccc;
				border-right: 1px solid #ccc;
				bottom: 0;
				right: 0;
			}
		}
		a:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			.post-img-wrap:after,
			.post-img-wrap:before {
				width: 100%;
				height: 100%;
			}
		}
		.post-arc{
			@include flex;
			-ms-flex-align: center;
			align-items: center;
		}
		.entry-content{
			line-height: 1.7;
			font-size: 14px;
			font-size: 1.4rem;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
	#page-navigation{
		padding: 30px 0;
		text-align: center;
		.current{
			padding: 10px 15px;
		}
		a,
		a:link,
		a:visited,
		a:active{
			padding: 10px 15px;
		}
		a:hover{
		}
	}
}
@include media(sp){
body.archive.common,
body.category.common{
	#primary-block{
		display: block;
		width: auto;
	}
	#primary{
		padding: 75px 10px 100px 10px;
		width: auto;
		margin: 0 auto;
		.page-title{
			text-align: center;
			font-size: 30px;
			font-size: 3rem;
			margin-bottom: 40px;
		}
		h1,h2,h3,h4,h5,h6{
			
			margin: 0;
		}
		.post-link{
			display: block;
			margin-bottom: 40px;
			.post-img-wrap{
				width: 100%;
				height: 250px;
			}
			.post-txt{
				width: auto;
				padding-left: 0;
			}
			.entry-header{

			}
			.post-date{

			}
			.post-ttl{
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		a,
		a:link,
		a:visited,
		a:active{
			@include trans;
			opacity: 1;
			filter: alpha(opacity=100);
		}
		a:hover{
		}
		.post-arc{
			display: block;
		}
		.entry-content{
			line-height: 1.8;
			font-size: 14px;
			font-size: 1.4rem;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
	#page-navigation{
		padding: 30px 0;
		text-align: center;
		.current{
			padding: 10px 15px;
		}
		a,
		a:link,
		a:visited,
		a:active{
			padding: 10px 15px;
		}
		a:hover{
		}
	}
}
}
@media screen and (min-width: 980px) {

}
@media screen and (max-width: 980px) {

}

