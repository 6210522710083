@charset "utf-8";
/*--------------------------------------------------------------
# Variable
--------------------------------------------------------------*/
$col-base:#bbb;
$col-bg: #fafafa;
$col-in-bg:rgba(234, 234, 231, 0.08);
$col-fot: #fff;
$col-link:#3b4650;
$col-hov:#d4d4d4;
$col-nav:#fff;
$brakeWSP: 320px;
$brakeWTb: 480px;
$brakeWPC: 980px;

$blue:#2e4c69;// ビルガイアブルー
$gray:#424242;// ビルガイアグレー
$black:#050505;  // ビルガイアブラック
$red:#e60512;

@mixin fonts-base{
  // font-family: 'Noto Sans JP', "Hiragino Kaku Gothic","ヒラギノ角ゴ Pro W3","ＭＳ Ｐゴシック",Frutiger,Helvetica,sans-serif;
  /* 游ゴシックを使用する場合 */
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

  /* 游ゴシックを使用しない場合 */
  // font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  // font-family: 'Modak', cursive;
}
@mixin font-m { font-family: "秀英明朝 M","Shuei Mincho M","游明朝体","Yu Mincho",YuMincho,"ヒラギノ明朝 Pro","Hiragino Mincho Pro","MS P明朝","MS PMincho",serif; }

@mixin fonts-alfabet{
font-family: 'Lato', sans-serif;
}
@mixin trans{
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
}
// @mixin flex{
// 	display: -webkit-flex;
// 	display: -moz-flex;
// 	display: -ms-flex;
// 	display: -o-flex;
// 	display: flex;
// }
// 自分で書いた
// ----------------------------------------------------------------------------------●

// z-indexの管理（プロジェクトごとに管理）
@mixin z-index($key){
  z-index: map-get($layer,$key);
}

 // クリアフィックス
@mixin clearfix{
  &:after{
    content: "";
    display: block;
    clear: both;
  }
}

// ゴーストボタン
// @include ghost(高さ,幅,角丸具合,線の色);
@mixin ghost( $height , $width , $radius , $color){
  height: $height;
  line-height: $height;
  width: $width;
  display: block;
  border-radius: $radius;
  border: 1px solid $color;
  text-align: center;
}

// 中央に配置---
@mixin center{
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform : translate(-50%,-50%);
  transform : translate(-50%,-50%);
}

// ----------------------------------------------------------------------------------●
// 画像を中央に配置
@mixin img-center{
  position: relative;
  overflow: hidden;
  img{
    @include center;
    height: 100%;
    width: auto;
  }
}

// ----------------------------------------------------------------------------------●
// CSS上で画像を左に配置
// @include img-list(画像のURL,高さ);
@mixin img-list( $url , $height){
  @include no-hover;
  &:before{
    content: "";
    background: ($url) no-repeat;
    width: $height;
    height: $height;
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    margin-right: 0.5rem;
  }
}

// ----------------------------------------------------------------------------------●
// ホバー
@mixin hover{
  opacity:0.7;
  filter: alpha(opacity=70);        /* ie lt 8 */
  -ms-filter: "alpha(opacity=70)";  /* ie 8 */
  -moz-opacity:0.7;                 /* FF lt 1.5, Netscape */
  -khtml-opacity: 0.7;              /* Safari 1.x */
  transition: all 0.2s linear;
}
@mixin no-hover{
  opacity:1;
  filter: alpha(opacity=100);        /* ie lt 8 */
  -ms-filter: "alpha(opacity=100)";  /* ie 8 */
  -moz-opacity:1;                 /* FF lt 1.5, Netscape */
  -khtml-opacity: 1;              /* Safari 1.x */
}

// ----------------------------------------------------------------------------------●
// svgの色を変更
@mixin svg($color:#fff) {
  path{
    color: $color;
    fill: currentColor;
  }
}

// ----------------------------------------------------------------------------------●
// flex-box
@mixin flex( $wrap:wrap , $justify:center, $align:space-between){
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
  -webkit-justify-content: $justify;
          justify-content: $justify;
  -webkit-align-content: $align;
          align-content: $align;
  img{

  }
}
// ----------------------------------------------------------------------------------●
// flex-box
@mixin flex-box( $wrap:wrap , $justify:center, $align:space-between){
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
  -webkit-justify-content: $justify;
          justify-content: $justify;
  -webkit-align-content: $align;
          align-content: $align;
  img{

  }
}

// ----------------------------------------------------------------------------------●
// flex-box（上下中央センター揃え）
@mixin flex-center{
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

// ----------------------------------------------------------------------------------●
// 不透明度
@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}


// 以下、どっかから引っ張ってきた!!!!!!!!!!!!!
// ----------------------------------------------------------------------------------●
// 角丸（デフォは円）
@mixin circle( $size: 5rem){
  width: $size;
  height: $size;
  border-radius: 100%;
}

// ----------------------------------------------------------------------------------●
//ボックスシャドウ
@mixin box-shadow( $h: 10px , $v: 10px , $b: 0px , $s: 0px , $c: #000000 ) {
  -webkit-box-shadow: $h $v $b $s $c;
     -moz-box-shadow: $h $v $b $s $c;
          box-shadow: $h $v $b $s $c;
}

// ----------------------------------------------------------------------------------●
//グラデーション
@mixin gradient($start-color, $end-color, $orientation){
  background: $start-color;
  @if $orientation == vertical{
    // vertical
    background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
  }
  @else if $orientation == horizontal{
    // horizontal
    background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  }
  @else{
    // radial
    background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  }
}

// ----------------------------------------------------------------------------------●
// 複数行の文末に「…」をつける
//@include abridgement(行間,文字数?,表示したい行数,#背景色);
@mixin mojimax($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: ($font-size*$line-height*$lines) *0.1+rem;//文字サイズ*行間*表示したい行数=全体の高さ
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '...';
    background: $color;//最後の文字を隠すための背景
    right: 0rem;
    text-align: center;
    width:1em !important;
    top:($font-size*$line-height*($lines - 1)) *0.1+rem;//文字サイズ*行間*表示したい行数-1 *開始位置が一行分多いため
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0rem;
    z-index: 2;
    background: $color;//表示させたい文字の行数に到達しなかった時に「…」を隠すための背景
  }
}

// ----------------------------------------------------------------------------------●
// 擬似クラス
// 最初の●個を選択
@mixin first($num) {@if $num == 1 {&:first-child {@content;}} @else {&:nth-child(-n + #{$num}) {@content;}}}

// 最後の●個を選択
@mixin last($num) {&:nth-last-child(-n + #{$num}) {@content;}}

// 最後から最初の●個を除いたものを選択。
@mixin after-first($num) {&:nth-child(n + #{$num + 1}) {@content;}}

// 最後から数えて●個目を選択。
@mixin from-end($num) {&:nth-last-child(#{$num}) {@content;}}

// ●個目から◆個目までを選択。
@mixin between($first, $last) {&:nth-child(n + #{$first}):nth-child(-n + #{$last}) {@content;}}

// ●個目から◆◆個目までの奇数個おきに選択。
@mixin odd-between($first, $last) {&:nth-child(odd):nth-child(n + #{$first}):nth-child(-n + #{$last}) {@content;}}

// ●個目から◆◆個目までの3個おきに選択。
@mixin n-between($num, $first, $last) {&:nth-child(#{$num}n):nth-child(n + #{$first}):nth-child(-n + #{$last}) {@content;}}

// ●個目を除いて、すべて選択。
@mixin all-but($num) {&:not(:nth-child(#{$num})) {@content;}}

// ●個おきに選択。
@mixin each($num) {&:nth-child(#{$num}n) {@content;}}
@mixin every($num) {&:nth-child(#{$num}n) {@content;}}

// 最初から●個目と最後から●個目を選択。
@mixin from-first-last($num) {&:nth-child(#{$num}),&:nth-last-child(#{$num}) {@content;}}

// ●●個中、真ん中を選択。
@mixin middle($num) {&:nth-child(#{round($num / 2)}) {@content;}}

// 最初の●個と最後の●個を除いて、すべて選択。
@mixin all-but-first-last($num) {&:nth-child(n + #{$num}):nth-last-child(n + #{$num}) {@content;}}

// ●●個中の最初の1個目を選択。
@mixin first-of($limit) {&:nth-last-child(#{$limit}):first-child {@content;}}

// ●●個中の最後の1個目を選択。
@mixin last-of($limit) {&:nth-of-type(#{$limit}):nth-last-of-type(1) {@content;}}

// ●個以上ならすべてを選択。
@mixin at-least($num) {$selector: &;$child: nth(nth($selector, -1), -1);&:nth-last-child(n + #{$num}),&:nth-last-child(n + #{$num}) ~ #{$child} {@content;}}

// ●個以下ならすべてを選択。
@mixin at-most($num) {$selector: &;$child: nth(nth($selector, -1), -1);&:nth-last-child(-n + #{$num}):first-child,&:nth-last-child(-n + #{$num}):first-child ~ #{$child} {@content;}}

// ●個から10個の間ならすべてを選択。
@mixin in-between($min, $max) {$selector: &;$child: nth(nth($selector, -1), -1);&:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child,&:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child ~ #{$child} {@content;}}

// 最初の1個目を選択。
@mixin first-child() {&:first-of-type {@content;}}

// 最後の1個目を選択。
@mixin first-child() {&:last-of-type {@content;}}

// 偶数個おきに選択。
@mixin even() {&:nth-child(even) {@content;}}

// 奇数個おきに選択。
@mixin odd() {&:nth-child(odd) {@content;}}

// 最初の1個目と最後の1個目を選択。
@mixin first-last() {&:first-child,&:last-child {@content;}}

// 唯一の子要素であるときに選択。
@mixin unique() {&:only-child {@content;}}
@mixin only() {&:only-child {@content;}}

// 唯一の子要素でないときに選択。
@mixin not-unique() {&:not(:only-child) {@content;}}

/// This mixin is used to automatically sort z-index in numerical order. But it
/// can also sort them in anti-numerical order, depending the parameters you use.
/// @group using functions
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - Number of children
/// @param {string} $direction [forward] - Direction of the sort
/// @param {number} $index [0] - Index of the sorting
@mixin child-index($num, $direction: 'forward', $index: 0) {
  @for $i from 1 through $num {
    @if ($direction == 'forward') {
      &:nth-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    } @else if ($direction == 'backward') {
      &:nth-last-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    }
  }
}
/// Used by the child-index mixin. It will returned the proper sorted numbers
/// depending on the `$index` value.
/// @access private
/// @param {number} $num - Number of children
/// @param {number} $index - Index of the sorting
@function order-index($i, $index) {
  @return ($index + $i);
}