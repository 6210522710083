body.page-template,
body.post-template,
body.category{
	#main-img{
		position: relative;
		min-width: 1200px;
		margin-bottom: 60px;
		.page-ttl{
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
			display: block;
			background: #fff;
			width: 40%;
			position: absolute;
			top: 50%;
			right: 0;
			-ms-transform: translate(0, -50%);
			-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%);
			z-index: 10;
			font-size: 34px;
			font-size: 3.4rem;
			@include fonts-alfabet;
			padding: 50px 0 50px 54px;
			color: #3b4650;
			span{
				display: block;
				position: relative;
			}
			span:before{
				content: '';
				width: 150px;
				height: 4px;
				background: #3b4650;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		#main-bg-wrap{
			width: 1200px;
			margin: 0 auto;
		}
		#main-img-bg{
			position: relative;
			padding-top: 10px;
			padding-left: 10px;
			width: 1050px;
			height: 400px;
		}
		#main-img-bg-inner{
			width: auto;
			height: 400px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			position: relative;
		}
		#main-img-bg-border{
			position: relative;
			padding-right: 10px;
			padding-bottom: 10px;
		}
		#main-img-bg-border:before {
			content: '';
			width: 160px;
			height: 1px;
			background: #000;
			position: absolute;
			bottom: 0;
			right: -20px;
		}
		#main-img-bg-border:after {
			content: '';
			width: 1px;
			height: 90px;
			background: #000;
			position: absolute;
			bottom: -20px;
			right: 0;
		}
		#main-img-bg:before {
			content: '';
			width: 160px;
			height: 1px;
			background: #000;
			position: absolute;
			top: 0;
			left: -20px;
		}
		#main-img-bg:after {
			content: '';
			width: 1px;
			height: 90px;
			background: #000;
			position: absolute;
			top: -20px;
			left: 0;
		}
	}
	.method-content{
		background: #eaedf0;
		.method-conponet{
			@include flex(wrap,left,space-between);
			width: 1240px;
			margin: 0 auto;
		}
		.method-block{
			width: 390px;
			margin-top: 30px;
			margin-right: 34px;
			background: #fff;
			.method-block-wrap{
				border-top: 6px solid #2e4c69;
			}
			.method-num-ttl{
				display: block;
				@include fonts-alfabet;
				text-align: center;
				font-weight: 700;
				font-size: 43px;
				font-size: 4.3rem;
				color: #9dadbc;
				background: #eaedf0;
			}
			.feature-block-img{
				text-align: center;
				margin-top: 30px;
				i{
					font-size: 50px;
					font-size: 5rem;
					color: #2e4c69;
				}
			}
			.feature-block-detail{
				padding: 0 30px 30px 30px;
				h3{
					color: #2e4c69;
					text-align: center;
					font-size: 26px;
					font-size: 2.6rem;
				}
			}
		}
		.method-block:nth-of-type(3n){
			margin-right: 0;
		}
	}
	.study-block{
		border-top: 2px solid #3b4650;
		border-bottom: 2px solid #3b4650;
		background: #f7f7f5;
		padding: 50px 10px;
		.study-men{
			width: 880px;
			margin: 0 auto;
			@include flex(wrap,left,space-between);
			-ms-flex-align: end;
			align-items: end;
			background: #fff;
		}
		.study-img{
			width: 30%;
			// height: 200px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.study-detail{
			width: 70%;
			padding: 0 20px 30px 30px;
			h3{
				color: #2e4c69;
				font-size: 24px;
				font-size: 2.4rem;
			}
			a,
			a:link,
			a:visited,
			a:active{
				display: block;
				background: #3e7bb5;
				color: #fff;
				width: 250px;
				text-align: center;
				padding: 18px 10px;
				opacity: 1;
				filter: alpha(opacity=100);
				.fas{
					padding-left: 10px;
				}
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
		}
		.cta-btn{
			position: relative;
			strong{
				position: absolute;
				top: -50px;
				left: 50%;
				margin-left: -150px;
				font-size: 12px;
				font-size: 1.2rem;
				text-align: left;
			}
			a,
			a:link,
			a:visited,
			a:active{
				position: relative;
				display: block;
				width: 340px;
				margin: 30px auto 0 auto;
				text-align: center;
				color: #fff;
				opacity: 1;
				filter: alpha(opacity=100);
				font-weight: bold;
				padding: 20px 0px;
				font-size: 14px;
				font-size: 1.4rem;
				background: rgb(153,222,130);
				background: -moz-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
				background: -webkit-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
				background: linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99de82",endColorstr="#4cbb93",GradientType=1);
				.fas{
					padding-left: 15px;
				}
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
		}
	}

	#line{
		border: 1px solid #282828;
		background: #fafafa;
		margin: 50px auto 0 auto;
		padding: 40px 36px;
		width: 900px;
		@include flex(wrap,center,space-between);
		-ms-flex-align: center;
		align-items: center;
			a,
			a:link,
			a:visited,
			a:active{
				@include flex(wrap,center,space-between);
				-ms-flex-align: center;
				align-items: center;
				background: #01b901;
				color: #fff;
				font-weight: bold;
				padding: 10px 20px;
				height: 50px;
				margin-left: 50px;
				border-radius: 4px;
				.fab,
				.fas{
					padding-left: 20px;
					font-size: 30px;
					font-size: 3rem;
				}
				.fas{
					font-size: 14px;
					font-size: 1.8rem;
				}
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
	}
	#line.etc{
			a,
			a:link,
			a:visited,
			a:active{
				padding: 13px 30px;
				background: #296966;
				border-radius: 0;
				.fab,
				.fas{
				}
				.fas{
					font-size: 14px;
					font-size: 1.8rem;
				}
			}
			a:hover{
			}
	}
	#contact{
		width: 1000px;
		padding: 100px 50px;
		margin: 0 auto 100px auto;
		background: #fff;
		table{
			tr{
				border-top: 1px solid rgba(0, 0, 0, 0.2);
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				th,td{
					font-size: 1.5px;
					font-size: 1.5rem;
					text-align: left;
					padding: 30px 10px;
					color: #2a2a2a;
					font-weight: normal;
				}
				th{
					width: 30%;
				}
				td{
					width: 70%;
				}
			}
		}
		.privacy-btn{
			margin: 40px 0;
			text-align: center;
			a,
			a:link,
			a:visited,
			a:active{
				text-decoration: underline;
				opacity: 1;
				filter: alpha(opacity=100);
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
		}
		select {
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			border-radius: 0;
			border: 0;
			margin: 0;
			background: none transparent;
			vertical-align: middle;
			font-size: inherit;
			color: inherit;
			display: block;
			border-radius: 4px;
			border: solid 1px #d8d8d8;
			width: 100%;
			font-size: 15px;
			font-size: 1.5rem;
			color: #333;
			padding: 7.5px 10px;
		}
		.form-caption{
			margin-top: 20px;
		}
		.date-time{
			@include flex(wrap,left,space-between);
			-ms-flex-align: center;
			align-items: center;
			margin-top: 10px;
			input[type=text] {
				width: 5em;
			}
			.wpcf7-form-control-wrap{
				display: inline;
			}
			.droptime{
				width: 5em;
			}
		}
		.must{
			background: red;
			font-size: 12px;
			font-size: 1.2rem;
			color: #fff;
			padding: 4px 6px;
			margin-left: 8px;
		}

		input[type=email],
		input[type=number],
		input[type=password],
		input[type=tel],
		input[type=text] {
			-o-box-sizing: border-box;
			-ms-box-sizing: border-box;
			box-sizing: border-box;
			width: 100%;
			padding: 10px;
			font-size: 16px;
			line-height: 18px;
			border: 1px solid #ccc;
		}
		input[type=submit]{
			display: block;
			position: relative;
			font-weight: 700;
			line-height: 1.5;
			text-align: center;
			box-sizing: border-box;
			cursor: pointer;
			border-radius: 0;
			width: 350px;
			margin: 50px auto 0 auto;
			@include trans;

			font-size: 16px;
			font-size: 1.6rem;
			color: #fff;
			padding: 20px 80px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
			border: 1px solid rgba(0, 0, 0, 0);
			position: relative;
			opacity: 1;
			filter: alpha(opacity=100);

			background: rgb(153,222,130);
			background: -moz-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			background: -webkit-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			background: linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99de82",endColorstr="#4cbb93",GradientType=1);
		}
		input[type=submit]:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
		}
	}
}

body.page-template-page-flow{
	.results-content{
		h2{
			text-align: center;
			color: #2e4c69;
			font-size: 30px;
			font-size: 3rem;
		}
		.results-block-wrap{
			display: block;
			width: 1080px;
			margin: 50px auto 0 auto;
			.results-block{
				width: 100%;
				display: block;
				margin-top: 50px;
				.results-block-detail{
					width: auto;
					padding: 0;
					p{
						margin: 0;
					}
					.fact-ttl{
						margin-bottom: 20px;
					}
					.fact-ttl-num{
						font-size: 40px;
						font-size: 4rem;
						display: block;
						position: relative;
						h3{
							margin: 0;
							font-size: 24px;
							font-size: 2.4rem;
							position: absolute;
							bottom: 8px;
							left: 70px;
						}
					}
					.fact-ttl:before{
						width: 90%;
					}
				}
			}
		}
	}
}

body.page-template-page-course-page-1week{
	.results-content{
		h2{
			text-align: center;
			color: #2e4c69;
			font-size: 30px;
			font-size: 3rem;
		}
		.results-block-wrap-flow{
			display: block;
			width: 1080px;
			margin: 50px auto 0 auto;
			.results-block{
				width: 100%;
				display: block;
				margin-top: 50px;
				.results-block-detail{
					width: auto;
					padding: 0;
					p{
						margin: 0;
					}
					.fact-ttl{
						margin-bottom: 20px;
					}
					.fact-ttl-num{
						font-size: 40px;
						font-size: 4rem;
						display: block;
						position: relative;
						h3{
							margin: 0;
							font-size: 24px;
							font-size: 2.4rem;
							position: absolute;
							bottom: 8px;
							left: 70px;
						}
					}
					.fact-ttl:before{
						width: 90%;
					}
				}
			}
		}
	}
}
body.post-template-page-voice,
body.post-template-page-interview,
body.post-template-page-blog-news{
	#primary{
		background: #f3f4f6;
		padding: 50px 0;
		article{
			.entry-content{
				padding: 0 10px;
				width: 900px;
				margin: 0 auto;
				h2{
					margin: 40px 0 20px 0;
				}
				p{
					line-height: 1.9;
					// width: 800px;
					// margin-left: auto;
				}
				figure{

					// width: 800px;
					// margin-left: auto;
				}
			}
			.fukidashi{
				@include flex;
				-ms-flex-align: center;
				align-items: center;
				width: 800px;
				margin-left: auto;
				.fukidashi-img{
					width: 10%;
					text-align: center;
					span{
						font-size: 1rem;
					}
				}
				.fukidashi-txt{
					width: 90%;
					padding-left: 20px;
					.fukidashi-txt-inner{
						padding: 20px 10px;
						border: 5px solid #3c4550;
						position: relative;
						background: #fff;
					}
					.fukidashi-txt-inner:before{
						content: "";
						position: absolute;
						top: 13px;
						left: -19px;
						width: 0;
						height: 0;
						border-style: solid;
						border-color: transparent;
						border-right-color: inherit;
						border-width: 7px;
					}
				}
			}
		}
	}
}
body.post-template-page-voice{
	.entry-header{
		.entry-title{
			color: $black;
			padding: 0.5em 0;
			border-top: solid 3px $black;
			border-bottom: solid 3px $black;
			font-size: 30px;
			font-size: 3rem;
			margin-bottom: 50px;
		}
	}
}

// Course Template
body.page-template,
body.post-template{
	.sec-com{
		.sec-com-wrap{
			.ttl-line{
				text-align: center;
			}
			.ttl-course-liine{
				font-size: 30px;
				font-size: 3rem;
				color: #2e4c69;
				position: relative;
				text-align: center;
				display: inline-block;
			}
			.ttl-course-liine:after {
				background: #2e4c69;
				content: '';
				display: block;
				width: 50px;
				height: 1px;
				position: absolute;
				top: 50%;
				right: -100px;
				-ms-transform: translate(-50%, 0%);
				-webkit-transform: translate(-50%, 0%);
				transform: translate(-50%, 0%);
			}
			.ttl-course-liine:before {
				background: #2e4c69;
				content: '';
				display: block;
				width: 50px;
				height: 1px;
				position: absolute;
				top: 50%;
				left: -50px;
				-ms-transform: translate(-50%, 0%);
				-webkit-transform: translate(-50%, 0%);
				transform: translate(-50%, 0%);
			}
		}
	}
	#course-lead-wrap{
		min-width: 1200px;
	}
	#course-lead-text{
		width: 900px;
		margin: 100px auto;
		h2{
			color: #050505;
			text-align: center;
			font-size: 20px;
			font-size: 2rem;
			line-height: 1.8;
			font-weight: normal;
		}
	}
	#course-lead-text.weeks{
		h2{
			font-size: 22px;
			font-size: 2.2rem;
			font-weight: bold;
			position: relative;
			margin-bottom: 70px;
			span{
				display: block;
				font-size: 13px;
				font-size: 1.3rem;
			}
		}
		h2:before {
			content: '';
			position: absolute;
			bottom: -15px;
			display: inline-block;
			width: 60px;
			height: 5px;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			background-color: black;
			border-radius: 2px;
		}
		.week-caps{
			border: 1px solid #282828;
			background: #fafafa;
			width: 900px;
			margin: 50px auto;
			padding: 40px 36px
		}
	}
	.program{
		.results-block-wrap{
			width: 900px;
			.results-block{
				display: block;
				.results-block-detail{
					padding: 50px 0;
					width: auto;
					text-align: center;
					.fas{
						color: #2e7572;
						font-size: 70px;
						font-size: 7rem;
					}
					h3{
						margin-top: 50px;
						color: #2e7572;
						font-size: 20px;
						font-size: 2rem;
						text-align: center;
						line-height: 1.3;
						span{
							margin-top: 20px;
							font-size: 14px;
							font-size: 1.4rem;
							display: block;
						}
					}
				}
			}
		}
	}
	.recomend-course-content{
		ul{
			list-style: none;
			text-align: left;
			li{
				font-weight: bold;
				font-size: 24px;
				font-size: 2.4rem;
				color: #2e4c69;
				padding: 26px 10px;
				border-bottom: 1px solid #2e4c69;
				text-indent: -3em;
				padding-left: 3em;
				span{
					padding: 12px;
					margin: 0 20px 0 0;
					width: 100px;
					height: 100px;
					border: 1px solid #2e4c69;
					border-radius: 100px;
				}
			}
		}
		.sec-com-wrap{
			width: 900px;
			margin: 0 auto;
		}
	}
	.features-course-content{
		#features-course-wrap{
			width: 900px;
			margin: 0 auto;
			@include flex;
			.features-course-block{
				-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
				-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
				width: 31%;
				background: #fff;
				margin-right: 30px;
				.features-course-block-img{
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					height: 200px;
				}
				.features-course-block-txt{
					padding: 10px;
					h3{
						color: #2e4c69;
						font-size: 20px;
						font-size: 2rem;
					}
					p{

					}
				}
			}
			.features-course-block:last-child{
				margin-right: 0;
			}
		}
		.feature-graf{
			width: 900px;
			margin: 0 auto;
			padding: 100px 0 0 0;
			position: relative;
			img{
				width: auto;
			}
			.feature-graf-txt{
				text-align: center;
				position:absolute;
				top: 100px;
				right: 0px;
				width: 450px;
				padding: 30px;
				border: 1px solid #2e4c69;
				-webkit-box-shadow: 5px 5px 0 0px #cbcbcb;
				-moz-box-shadow: 5px 5px 0 0px #cbcbcb;
				box-shadow: 5px 5px 0 0px #cbcbcb;
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: underline;
					opacity: 1;
					filter: alpha(opacity=100);
				}
				a:hover{
					opacity: 0.6;
					filter: alpha(opacity=60);
				}
			}
		}
	}
}
// Course Template

// Interview Page
body.post-template-page-interview,
body.post-template-page-blog-news{
	#primary{
		    background: transparent;
		    position: relative;
		    margin-top: 260px;
		    padding-bottom: 0;
		.instructor-profile{
			position: absolute;
			top: -200px;
			left: 50%;
			-ms-transform: translate(-50%,0);
			-webkit-transform: translate(-50%,0);
			transform: translate(-50%,0);
			.instructor-wrap{
				width: 900px;
				margin: 0 auto;
				position: relative;
			}
			.instructor-img{
				img{
					width: 400px;
				}
				// background-size: cover;
				// background-repeat: no-repeat;
				// background-position: center;
			}
			.instructor-txt{
				position: absolute;
				bottom: -60px;
				right: 50px;
				background: #fff;
				width: 550px;
				border-left: 5px solid #3b4650;
				padding: 30px 20px;
				h3{
					margin: 0;
					font-size: 22px;
					font-size: 2.2rem;
					padding: 0 0 10px 0;
					border-bottom: 1px solid #3b4650;
				}
				h4{
					margin: 10px 0;
					font-size: 16px;
					font-size: 1.6rem;
				}
				p{
					margin: 0;
				}
			}
			.instructor-sns{
				@include flex(wrap,left,space-between);
				-ms-flex-align: center;
				align-items: center;
				margin-top: 20px;
				i{
					color: #000000;
					padding: 0 10px;
					font-size: 25px;
					font-size: 2.5rem;
				}
			}
		}
		article{
			background: #f3f4f6;
			padding: 240px 10px 50px 10px;
		}
	}
}
// Interview Page

body.post-template-page-blog-news{
	#primary{
		margin-top: 80px;
		article{
			padding-top: 50px;
			.entry-header{
				.entry-title{
					text-align: center;
					font-size: 30px;
					font-size: 3rem;
				}
			}
		}
	}
	#main-img{
		#main-bg-wrap{
		}
		#main-img-bg{
			width: 1200px;
		}
	}
}


@include media(sp){
body.page-template,
body.post-template,
body.category{
	#main-img{
		min-width: auto;
		padding-top: 50px;
		width: 100%;
		padding-left: 10px;
		.page-ttl{
			padding: 20px 0 20px 30px;
			margin: 0;
			font-size: 30px;
			font-size: 3rem;
			top: auto;
			width: 80%;
			bottom: 30px;
			-ms-transform: translate(0, 0);
			-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
			span{
				display: block;
				position: relative;
			}
			span:before{
				width: 100%;
			}
		}
		#main-bg-wrap{
			width: 100%;
		}
		#main-img-bg{
			width: auto;
			height: auto;
		}
		#main-img-bg-inner{
			width: auto;
			height: 400px;
		}
		#main-img-bg-inner.sp{
			height: auto;
			background-image: none;
		}
		#main-img-bg-border{
			padding-right: 20px;
			padding-bottom: 20px;
		}
		#main-img-bg-border:before {
			width: 100px;
			right: 0px;
		}
		#main-img-bg-border:after {
			height: 40px;
		}
		#main-img-bg:before {
			width: 100px;
		}
		#main-img-bg:after {
			height: 40px;
		}
	}
	.method-content{
		.method-conponet{
			display: block;
			width: auto;
			margin: 0 auto;
			padding: 0 10px;
		}
		.method-block{
			width: 100%;
			background: transparent;
			.method-block-wrap{
				background: #fff;
			}
			.method-num-ttl{
				font-size: 35px;
				font-size: 3.5rem;
				background: transparent;
			}
			.feature-block-img{
				text-align: center;
				margin-top: 30px;
				i{
					font-size: 50px;
					font-size: 5rem;
				}
			}
			.feature-block-detail{
				h3{
					font-size: 26px;
					font-size: 2.6rem;
				}
			}
		}
		.method-block:nth-of-type(3n){
			margin-right: 0;
		}
	}
	.study-block{
		padding: 30px 10px;
		.study-men{
			width: auto;
			margin: 0 auto;
			display: block;
			padding: 20px 0 0 0;
		}
		.study-img{
			width: 100%;
			height: auto;
			background-size: contain;
		}
		.study-detail{
			width: 100%;
			padding: 30px 30px;
			h3{
				text-align: center;
				color: #2e4c69;
				font-size: 20px;
				font-size: 2rem;
				margin-top: 0;
			}
			a,
			a:link,
			a:visited,
			a:active{
				width: 100%;
			}
			a:hover{
			}
		}
		.cta-btn{
			strong{
			}
			a,
			a:link,
			a:visited,
			a:active{
				width: 100%;
			}
			a:hover{
				opacity: 1;
				filter: alpha(opacity=100);
			}
		}
	}
	#line{
		margin: 50px 10px 0 10px;
		width: auto;
		display: block;
			a,
			a:link,
			a:visited,
			a:active{
				margin-left: 0;
				border-radius: 4px;
				.fab,
				.fas{
				}
			}
			a:hover{
			}
	}
	#contact{
		width: auto;
		padding: 50px 30px;
		table{
			tr{
				display: block;
				border-top: none;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				th,td{
					display: block;
					width: auto;
				}
				th{
					font-weight: bold;
					padding-bottom: 0;
				}
				td{

				}
			}
		}

		input[type=email],
		input[type=number],
		input[type=password],
		input[type=tel],
		input[type=text] {
		}
		input[type=submit]{
			width: 100%;
			opacity: 1;
			filter: alpha(opacity=100);
		}
		input[type=submit]:hover{
			opacity: 1;
			filter: alpha(opacity=100);
		}
	}
}
body.page-template-page-flow{
	.results-content{
		h2{
			font-size: 23px;
			font-size: 2.3rem;
		}
		.results-block-wrap{
			display: block;
			width: auto;
			margin: 50px auto 0 auto;
			.results-block{
				width: 100%;
				display: block;
				margin-top: 50px;
				.results-block-detail{
					width: auto;
					padding: 0;
					p{
						margin: 0;
					}
					.fact-ttl{
						padding-top: 10px;
					}
					.fact-ttl-num{
						font-size: 30px;
						font-size: 3rem;
						display: block;
						position: relative;
						h3{
							margin: 0;
							font-size: 24px;
							font-size: 2.4rem;
							position: absolute;
							bottom: 8px;
							left: 70px;
						}
					}
					.fact-ttl:before{
						width: 70%;
						top: 0;
					}
				}
			}
		}
	}
}

body.page-template-page-course-page-1week{
	.results-content{
		h2{
			font-size: 23px;
			font-size: 2.3rem;
		}
		.results-block-wrap-flow{
			display: block;
			width: auto;
			margin: 50px auto 0 auto;
			.results-block{
				width: 100%;
				display: block;
				margin-top: 50px;
				.results-block-detail{
					width: auto;
					padding: 0;
					p{
						margin: 0;
					}
					.fact-ttl{
					}
					.fact-ttl-num{
						font-size: 40px;
						font-size: 4rem;
						display: block;
						position: relative;
						h3{
							margin: 0;
							font-size: 24px;
							font-size: 2.4rem;
							position: absolute;
							bottom: 8px;
							left: 70px;
						}
					}
					.fact-ttl:before{
						width: 70%;
					}
				}
			}
		}
	}
}
// Course Template
body.page-template,
body.post-template{
	.sec-com{
		.sec-com-wrap{
			.ttl-line{
			}
			.ttl-course-liine{
				font-size: 28px;
				font-size: 2.8rem;
			}
			.ttl-course-liine:after {
				width: 20px;
				right: -40px;
			}
			.ttl-course-liine:before {
				width: 20px;
				left: -20px;
			}
		}
	}
	#course-lead-wrap{
		min-width: auto;
	}
	#course-lead-text{
		width: auto;
		padding: 10px;
		margin: 50px auto;
		h2{
			font-size: 18px;
			font-size: 1.8rem;
		}
	}
	#course-lead-text.weeks{
		h2{
			font-size: 20px;
			font-size: 2rem;
			span{
				font-size: 11px;
				font-size: 1.1rem;
			}
		}
		h2:before {
			content: '';
			position: absolute;
			bottom: -15px;
			display: inline-block;
			width: 60px;
			height: 5px;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			background-color: black;
			border-radius: 2px;
		}
		.week-caps{
			border: 1px solid #282828;
			background: #fafafa;
			width: auto;
			margin: 30px auto;
			padding: 30px 26px;
		}
	}
	.program{
		.results-block-wrap{
			padding: 0;
			@include flex(wrap,left,space-between);
			width: auto;
			.results-block{
				width: 48%;
				margin: 20px 1%;
				.results-block-detail{
					padding: 10px 0 0 0;
					.fas{
						font-size: 50px;
						font-size: 5rem;
					}
					h3{
						font-size: 16px;
						font-size: 1.6rem;
						span{
							font-size: 11px;
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}
	.recomend-course-content{
		padding: 50px 10px;
		ul{
			li{
				position: relative;
				font-size: 20px;
				font-size: 2.0rem;
				padding: 26px 10px 26px 85px;
				text-indent: 0;
				span{
					position: absolute;
					left: 10px;
					top: 50%;
					line-height: 0;
					padding: 23px 0px;
					margin: -25px 10px 0 0;
					width: 50px;
					height: 50px;
					border: 1px solid #2e4c69;
					border-radius: 100px;
					text-align: center;
				}
			}
		}
		.sec-com-wrap{
			width: auto;
		}
	}
	.features-course-content{
		padding: 0 10px;
		#features-course-wrap{
			width: auto;
			display: block;
			.features-course-block{
				width: auto;
				margin: 0 0 30px 0;
				.features-course-block-img{
					height: 200px;
				}
				.features-course-block-txt{
					h3{
						font-size: 20px;
						font-size: 2rem;
					}
					p{

					}
				}
			}
		}
		.feature-graf{
			width: auto;
			margin: 0 auto;
			padding: 50px 10px 0 10px;
			img{
				width: 100%;
			}
			.feature-graf-txt{
				position:static;
				width: auto;
				margin: 30px 0 0 0;
				a,
				a:link,
				a:visited,
				a:active{
				}
				a:hover{
				}
			}
		}
	}
}
// Course Template

body.post-template-page-voice,
body.post-template-page-interview,
body.post-template-page-blog-news{
	#primary{
		background: #f3f4f6;
		padding: 50px 0;
		article{
			.entry-content{
				width: auto;
				p{
					width: auto;
				}
				figure{

					width: auto;
					margin-left: auto;
				}
			}
			.fukidashi{
				width: auto;
				.fukidashi-img{
					width: 20%;
					span{
					}
				}
				.fukidashi-txt{
					width: 80%;
					.fukidashi-txt-inner{
					}
					.fukidashi-txt-inner:before{
					}
				}
			}
		}
	}
}
body.post-template-page-voice{
	.entry-header{
		.entry-title{
			font-size: 23px;
			font-size: 2.3rem;
		}
	}
}

// Interview Page
body.post-template-page-interview,
body.post-template-page-blog-news{
	#primary{
		    background: transparent;
		    position: relative;
		    margin-top: 30px;
		    padding-bottom: 0;
		    padding-top: 20px;
		.instructor-profile{
			padding: 0 20px;
			position: static;
			-ms-transform: translate(0, 0);
			-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
			.instructor-wrap{
				width: auto;
			}
			.instructor-img{
				img{
					width: auto;
				}
				// background-size: cover;
				// background-repeat: no-repeat;
				// background-position: center;
			}
			.instructor-txt{
				position: static;
				width: auto;
				padding: 20px 20px;
				h3{
					margin: 0;
					font-size: 18px;
					font-size: 1.8rem;
				}
				h4{
					margin: 10px 0;
					font-size: 15px;
					font-size: 1.5rem;
				}
				p{
					margin: 0;
				}
			}
			.instructor-sns{
				@include flex(wrap,left,space-between);
				-ms-flex-align: center;
				align-items: center;
				margin-top: 20px;
				i{
					color: #000000;
					padding: 0 10px;
					font-size: 23px;
					font-size: 2.3rem;
				}
			}
		}
		article{
			margin-top: 30px;
			background: #f3f4f6;
			padding: 10px 10px 30px 10px;
		}
	}
}
// Interview Page
body.post-template-page-blog-news{
	#primary{
		article{
			.entry-header{
				.entry-title{
				}
			}
		}
	}
	#main-img{
		#main-bg-wrap{
		}
		#main-img-bg{
			width: auto;
		}
	}
}


}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

