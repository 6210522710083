@charset "UTF-8";

#masthead{
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index:999;
	background: #fff;
	@include trans;
	#header-wrap{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		padding: 15px 10px;
		@include flex(wrap,space-between,space-between);
	}
	.site-branding{
		.site-title{
			margin: 0;
			a,
			a:link,
			a:visited,
			a:active{
				opacity: 1;
				filter: alpha(opacity=100);
				svg{
					width: 170px;
				}
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
		}
	}
	#site-navigation{
		@include flex;
		-ms-flex-align: center;
		align-items: center;
		.contact{
			background: #296966;
			color: #fff;
			font-size: 15px;
			font-size: 1.5rem;
			font-weight: bold;
			display: block;
			padding: 15px 30px;
			margin: 0 0 0 30px;
			.fas{
				padding-left: 15px;
			}
		}
		#primary-menu{
			list-style: none;
			padding: 0;
			margin: 0;
			@include flex;
			li{
				a,
				a:link,
				a:visited,
				a:active{
					display: block;
					opacity: 1;
					filter: alpha(opacity=100);
					color: #3b4650;
					font-weight: bold;
					padding: 8px 10px;
					font-size: 14px;
					font-size: 1.4rem;
					text-align: center;
					span{
						display: block;
						font-size: 11px;
						font-size: 1.1rem;
					}
				}
				a:hover{
					opacity: 0.6;
					filter: alpha(opacity=60);
				}
			}
		}
	}
	#humbergur-btn-wrap{
		display: none;
	}
}
#masthead.show{
	position: fixed;
	top: 0px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}



@include media(sp){


#masthead{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background: #fff;
	max-height: 45px;
	#header-wrap{
		width: auto;
		position: relative;
		display: block;
		padding: 10px 10px;
	}
	.site-branding{
		.site-title{
			line-height: 0;
			a,
			a:link,
			a:visited,
			a:active{
				svg{
					width: 100px;
				}
			}
			a:hover{
				opacity: 1;
				filter: alpha(opacity=100);
			}
		}
	}
	#site-navigation{
		display: block;
		position: fixed;
		left: 0;
		top: -100vh;
		width: 100%;
		padding: 0 10px;
		background: #fff;
		height: 100vh;
		@include trans;
		.contact{
			margin: 50px 0 0 0;
			text-align: center;
			.fas{
			}
		}
		#primary-menu{
			display: block;
			li{
				a,
				a:link,
				a:visited,
				a:active{
					text-align: left;
					padding: 10px;
					border-bottom: 1px solid #282828;
					span{
						padding-left: 10px;
						display: inline;
					}
				}
				a:hover{
					opacity: 1;
					filter: alpha(opacity=100);
				}
			}
		}
	}
	#humbergur-btn-wrap{
		display: block;
		margin: auto 0;
		align-items: center;
		position: absolute;
		top: 10px;
		right: 18px;
		width: 25px;
		height: 25px;
		#humbergur-btn{
			padding: 0;
			width: 100%;
			height: 100%;
			background: none;
			border: none;
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;
			cursor: pointer;
			position: relative;
			span {
				display: block;
				width: 100%;
				height: 100%;
				line-height: 1;
				word-break: keep-all;
				position: relative;
			}
			span:before {
				content: "";
				margin: auto;
				width: 100%;
				height: 2px;
				display: block;
				background-color: #828484;
				transition: opacity .3s;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
			}
		}
		#humbergur-btn:after,
		#humbergur-btn:before {
			content: "";
			width: 100%;
			height: 2px;
			display: block;
			background-color: #828484;
			transition: transform .3s,width .3s;
			position: absolute;
			left: 0;
		}
		#humbergur-btn:after{
			bottom: 4px;
		}
		#humbergur-btn:before{
			top: 4px;
		}
	}
}
#masthead.open{
	#header-wrap{
		.site-branding{
			position: relative;
			z-index: 10;
		}
	}
	#site-navigation{
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding: 60px 10px 0 10px;
		background: #fff;
		height: 100vh;
		.contact{
			margin: 50px 0 0 0;
			text-align: center;
			.fas{
			}
		}
		#primary-menu{
			display: block;
			li{
				a,
				a:link,
				a:visited,
				a:active{
					padding: 10px;
					border-bottom: 1px solid #282828;
				}
				a:hover{
					opacity: 1;
					filter: alpha(opacity=100);
				}
			}
		}
	}
	#humbergur-btn-wrap{
		z-index: 20;
		#humbergur-btn {
			background-color: transparent;
			span:before{
				 background-color: transparent;
			}
		}
		#humbergur-btn:before {
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			top: 11px;
		}
		#humbergur-btn:after {
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			bottom: 12px;
		}

	}
}
}

// SMP
@media all and (max-width: $brakeWPC) {

}
// SMP
// tab
// @media all and (min-width: $brakeWSP) {
// 	body.common{
// 	}
// }
// tab
// PC
@media all and (min-width: $brakeWPC) {

}
// PC
