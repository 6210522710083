body{
	a.viewmore-btn,
	a.viewmore-btn:link,
	a.viewmore-btn:visited,
	a.viewmore-btn:active{
		margin: 30px auto 0 auto;
		display: block;
		text-align: center;
		background: #3e7bb5;
		color: #fff;
		@include fonts-alfabet;
		font-weight: 700;
		width: 250px;
		padding: 20px 0;
		.fas{
			padding-left: 10px;
		}
	}
	a.viewmore-btn:hover{
		opacity: 0.6;
		filter: alpha(opacity=60);
	}
	#breadcrumbs-wrap{
		background: #fff;
		border-top: 1px solid #3b4650;
		min-width: 1200px;
		.breadcrumbs{
			width: 1200px;
			margin: 0 auto;
			padding: 4px 10px;
			color: #3b4650;
			span{
				font-size: 12px;
				font-size: 1.2rem;
				color: #3b4650;
			}
			.fas{
				padding: 0 10px;
			}
			a,
			a:link,
			a:visited,
			a:active{
				font-size: 12px;
				font-size: 1.2rem;
				color: #3b4650;
			}
			a:hover{
				opacity: 0.6;
				filter: alpha(opacity=60);
			}
		}
	}
	#page{
		padding-top: 120px;
	}
	.mini-cta{
	    background: #eaedf0;
	    .mini-cta-wrap{
			padding: 40px 10px;
		    width: 1000px;
		    margin: 0 auto;
	    }
	    h2{
	    	margin: 0;
	    	text-align: center;
	    }
		a,
		a:link,
		a:visited,
		a:active{
			position: relative;
			display: block;
			width: 340px;
			margin: 16px auto 0 auto;
			text-align: center;
			color: #fff;
			opacity: 1;
			filter: alpha(opacity=100);
			font-weight: bold;
			padding: 20px 0px;
			font-size: 14px;
			font-size: 1.4rem;
			background: #296966;
			// background: rgb(153,222,130);
			// background: -moz-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			// background: -webkit-linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			// background: linear-gradient(110deg, rgba(153,222,130,1) 0%, rgba(76,187,147,1) 100%);
			// filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99de82",endColorstr="#4cbb93",GradientType=1);
			
			.fas{
				padding-left: 15px;
			}
		}
		a:hover{
			opacity: 0.6;
			filter: alpha(opacity=60);
		}
	}
}
body.page-template-default{
	#primary{
		width: 1000px;
		padding: 0 50px 100px 50px;
		margin: 0 auto;
		.entry-header{
			.entry-title{
				font-size: 30px;
				font-size: 3rem;
			}
		}
	}
}
@include media(sp){
body{
	#breadcrumbs-wrap{
		min-width: auto;
		.breadcrumbs{
			width: auto;
			span{
				font-size: 10px;
				font-size: 1rem;
			}
			.fas{
			}
			a,
			a:link,
			a:visited,
			a:active{
				font-size: 10px;
				font-size: 1rem;
			}
			a:hover{
			}
		}
	}
	#page{
		padding-top: 0;
	}
	.mini-cta{
	    background: #eaedf0;
	    .mini-cta-wrap{
			padding: 70px 10px;
		    width: auto;
		    margin: 0 auto;
	    }
	    h2{
			font-size: 20px;
			font-size: 2rem;
	    }
		a,
		a:link,
		a:visited,
		a:active{
			width: 100%;
			.fas{
			}
		}
		a:hover{
			opacity: 1;
			filter: alpha(opacity=100);
		}
	}
}
body.page-template-default{
	#primary{
		width: auto;
		padding: 70px 10px 50px 10px;
		margin: 0 auto 100px auto;
		background: #fff;
		.entry-header{
			.entry-title{
				font-size: 30px;
				font-size: 3rem;
			}
		}
	}
}
}

@media screen and (min-width: 980px) {

}
@media screen and (max-width: 980px) {

}

